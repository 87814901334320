import auth from './auth'
import users from './users'
import customers from './customers'
import services from './services'
import externalServices from './externalServices'
import dashboard from './dashboard'
import suppliers from './suppliers'

export default {
  ...auth,
  ...users,
  ...customers,
  ...services,
  ...dashboard,
  ...suppliers,
  ...externalServices
}
