export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'first-view',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import('@/views/ChangePassword.vue')
      },
      {
        path: '/clientes',
        component: () => import('@/views/customers/CustomersIndex.vue'),
        children: [
          {
            path: '/',
            name: 'clientes',
            component: () => import('@/views/customers/Customers.vue')
          },
          {
            path: 'detalle/:customerId',
            name: 'detalle-cliente',
            component: () => import('@/views/customers/CustomerDetail.vue')
          }
        ]
      },
      {
        path: '/servicios',
        component: () => import('@/views/services/ServicesIndex.vue'),
        children: [
          {
            path: '/',
            name: 'servicios',
            component: () => import('@/views/services/Services.vue')
          },
          {
            path: '/new',
            name: 'nuevo-servicio',
            component: () => import('@/views/services/NewServices.vue')
          },
          {
            path: 'detalle/:serviceId/:customerId?',
            name: 'detalle-servicio',
            component: () => import('@/views/services/ServiceDetail.vue')
          }
        ]
      }
    ]
  }
]
