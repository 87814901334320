import api from '@/lib/api'

export default {
  async getSuppliersList ({ dispatch }, _) {
    try {
      const payload = await api.getSuppliersListAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getSupplierCustomerById ({ dispatch }, supplierId) {
    try {
      const payload = await api.getSupplierCustomerByIdAPI(supplierId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
