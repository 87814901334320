const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async login ({ email, password }) {
    const response = await fetch(`${API_URL}/customer/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },

  async changePasswordAPI (data) {
    const response = await fetch(`${API_URL}/customer/change/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async validateSession () {
    const response = await fetch(`${API_URL}/customer/auth/validate-session`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.message))
    } else {
      return Promise.reject(new Error(resp.message))
    }
  }
}
