const API_URL = process.env.VUE_APP_API_URL

export default {
  async userForgot (email) {
    const response = await fetch(`${API_URL}/customer/users/forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else if (response.status === 400) {
      return Promise.reject(new Error(result.message))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },

  async passwordRecovery ({ token, password }) {
    const response = await fetch(`${API_URL}/customer/users/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token
      },
      body: JSON.stringify({ password })
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else if (response.status === 400) {
      return Promise.reject(new Error(result.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  }
}
