import router from '../../router'

export default {
  setAuthenticationValue (state, data) {
    state.authentication[data.name] = data.value
  },

  setAuthValues (state, payload) {
    state.authentication = payload
  },

  signOut (state) {
    state.authentication = {
      email: null,
      name: null,
      token: null,
      userType: null,
      loading: false
    }
    localStorage.removeItem('access-token')
    router.push({ name: 'login' })
  }
}
