import api from '@/lib/api'

export default {
  async getDashboard ({ dispatch }, { startDate, endDate }) {
    try {
      const { payload } = await api.getDashboardAPI(startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
