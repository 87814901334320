const API_URL = process.env.VUE_APP_API_URL

export default {
  async saveRequestServiceExternalAPI (data) {
    const response = await fetch(`${API_URL}/customer/external/services`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400 || response.status === 401) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async AddDocumentFileExternalAPI (documentInfo) {
    console.log('==>', documentInfo)
    const { document, ...body } = documentInfo
    const formData = new FormData()
    if (document) formData.append('document', document.file)
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const response = await fetch(`${API_URL}/customer/external/services/document`, {
      method: 'POST',
      headers: {
        // Authorization: getToken()
      },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async deleteDocumentCustomerExternalAPI (documentId) {
    const response = await fetch(`${API_URL}/customer/external/document/remove`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
        // Authorization: getToken()
      },
      body: JSON.stringify({ documentId })
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getMxCoordinatorAPI () {
    const response = await fetch(`${API_URL}/customer/catalogs/mx-coordinator`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getCommercialExecutiveAPI () {
    const response = await fetch(`${API_URL}/customer/catalogs/commercial-executive`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
