export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: { auth: false }
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('@/views/Recovery.vue'),
    meta: { auth: false }
  }
]
