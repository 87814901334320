import api from '@/lib/api'

export default {
  async saveRequestServiceExternal ({ dispatch }, data) {
    try {
      const { message } = await api.saveRequestServiceExternalAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async AddDocumentFileExternal ({ dispatch }, { document }) {
    try {
      const { success, message, documentId, documentName } = await api.AddDocumentFileExternalAPI(document)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return { success, documentId, documentName }
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return { sucess: false }
    }
  },
  async deleteDocumentCustomerExternal ({ dispatch }, { documentId }) {
    try {
      const { message } = await api.deleteDocumentCustomerExternalAPI(documentId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getMxCoordinator () {
    try {
      const payload = await api.getMxCoordinatorAPI()
      return payload
    } catch (error) {
      return false
    }
  },
  async getCommercialExecutive () {
    try {
      const payload = await api.getCommercialExecutiveAPI()
      return payload
    } catch (error) {
      return false
    }
  }
}
