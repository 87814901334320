import ui from './ui'
import auth from './authentication'
import users from './users'
import customers from './customers'
import services from './services'
import dashboard from './dashboard'
import suppliers from './suppliers'
import externalServices from './externalServices'

export default {
  ...ui,
  ...auth,
  ...users,
  ...customers,
  ...services,
  ...dashboard,
  ...suppliers,
  ...externalServices
}
