import api from '@/lib/api'

export default {
  async getCustomers ({ dispatch }, { page = 1, limit = 10, search = '' }) {
    try {
      const payload = await api.getCustomersAPI(page, limit, search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getCustomerById ({ dispatch }, customerId) {
    try {
      const payload = await api.getCustomerByIdAPI(customerId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getMyCustomerDetail ({ dispatch }, _) {
    try {
      const payload = await api.getMyCustomerDetailAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
