import api from '@/lib/api'

export default {
  async getServices ({ dispatch }, { page = 1, limit = 10, search = '', status = '' }) {
    try {
      const payload = await api.getServicesAPI(page, limit, search, status)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getServiceById ({ dispatch }, { serviceId, customerId }) {
    try {
      const payload = await api.getServiceByIdAPI(serviceId, customerId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getServiceByCustomerId ({ dispatch }, { customerId, page = 1, limit = 10, search = '', status = '' }) {
    try {
      const payload = await api.getServiceByCustomerIdAPI(customerId, page, limit, search, status)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return { succes: false, services: [] }
    }
  },
  async getServiceDocumentsById ({ dispatch }, { serviceId, customerId }) {
    try {
      const payload = await api.getServiceDocumentsByIdAPI(serviceId, customerId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async saveRequestService ({ dispatch }, data) {
    try {
      const { message } = await api.saveRequestServiceAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async AddDocumentFile ({ dispatch }, { document }) {
    try {
      const { success, message, documentId, documentName } = await api.AddDocumentFileAPI(document)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return { success, documentId, documentName }
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return { sucess: false }
    }
  },
  async deleteDocumentCustomer ({ dispatch }, { documentId }) {
    try {
      const { message } = await api.deleteDocumentCustomerAPI(documentId)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
