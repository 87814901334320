import admin from './admin'
import auth from './auth'
import services from './services'

export default [
  ...admin,
  ...auth,
  ...services,
  { path: '*', component: () => import('@/views/ErrorPage.vue') }
]
