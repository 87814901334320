import api from '@/lib/api'

export default {
  async userForgot ({ commit, dispatch, state }, email) {
    try {
      const { message } = await api.userForgot(email)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async passwordRecovery ({ commit, dispatch, state }, data) {
    try {
      const { message } = await api.passwordRecovery(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
