const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getServicesAPI (page = 1, limit = 10, search = '', status = '') {
    const response = await fetch(`${API_URL}/customer/services?page=${page}&limit=${limit}&search=${search}&status=${status}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getServiceByIdAPI (servicesId, customerId = null) {
    const response = await fetch(`${API_URL}/customer/services/${servicesId}${customerId ? `/${customerId}` : ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getServiceByCustomerIdAPI (customerId, page = 1, limit = 10, search = '', status = '') {
    const response = await fetch(`${API_URL}/customer/customers/${customerId}/services?page=${page}&limit=${limit}&search=${search}&status=${status}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async getServiceDocumentsByIdAPI (serviceId, customerId = null) {
    const response = await fetch(`${API_URL}/customer/results/services/${serviceId}${customerId ? `/${customerId}` : ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async saveRequestServiceAPI (data) {
    const response = await fetch(`${API_URL}/customer/services`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })

    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400 || response.status === 401) {
        throw new Error(resp.error)
      }
    }
    return resp
  },
  async AddDocumentFileAPI (documentInfo) {
    console.log('==>', documentInfo)
    const { document, ...body } = documentInfo
    const formData = new FormData()
    if (document) formData.append('document', document.file)
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const response = await fetch(`${API_URL}/customer/services/document`, {
      method: 'POST',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async deleteDocumentCustomerAPI (documentId) {
    const response = await fetch(`${API_URL}/customer/document/remove`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify({ documentId })
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
