<template>
  <div id="app">
    <the-notification />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    TheNotification: () => import('@/components/ui/TheNotifications.vue')
  },
  mounted () {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024 && this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      } else if (window.innerWidth >= 1024 && !this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      }
    })
  }
}
</script>

<style lang="sass">
  $body-background-color: #f4f4f4;

  @import "bulma/bulma.sass";
  @import "vue-select/src/scss/vue-select.scss";
</style>

<style lang="scss">
  :root {
    --error-color: #ff3860;
    --primary-blue: #007ebf;
    --primary-green: #00b00c;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto !important;
    background-color: #f2f3f5;
    font-family: 'Source Sans Pro', sans-serif;
  }

  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
  }

  #app > .hero {
    flex: 1;
  }

  // Level
  h1.title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: 0.96px;
    color: #5d6990;
  }
  nav.level {
    padding: 0.9rem 1.5rem;
    background-color: white;
  }
  .level-item-img {
    width: 30px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
  .level:not(:last-child) {
    margin-bottom: 0;
  }

  .inner-section {
    padding: 1.5rem;
    min-height: calc(100vh - 10rem);
  }

  // Botones
  .button[disabled], fieldset[disabled] .button, .button[disabled]:hover {
    background-color: white;
    border-color: #dbdbdb;
    box-shadow: none;
    opacity: 0.5;
    color: black;
  }
  .button_ibs {
    background-color: var(--primary-green);
    border: 1px solid var(--primary-green);
    opacity: 0.79;
    color: white;
    transition: opacity 0.5s ease-in-out;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    font-weight: 600;
  }
  .button_ibs.sm_btn {
    font-size: 14px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .button_ibs:hover,
  .button_ibs:active,
  .button_ibs:focus,
  .button_ibs:visited,
  .button_ibs.is-active,
  .button_ibs.is-visited {
    border: 1px solid var(--primary-green);
    opacity: 1;
    color: white;
  }
  ::placeholder {
    color: #5d6990;
    font-weight: 600;
  }

  .button_ibs_outlined {
    background-color: white;
    border: 1px solid var(--primary-green);
    color: #404254;
    opacity: 0.79;
    font-weight: 600;
    transition: opacity 0.5s ease-in-out;
    font-size: 14px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .button_ibs_outlined:hover,
  .button_ibs_outlined:active,
  .button_ibs_outlined:focus,
  .button_ibs_outlined:visited,
  .button_ibs_outlined.is-active,
  .button_ibs_outlined.is-visited {
    border: 1px solid var(--primary-green);
    opacity: 1;
  }

  .is_btn_status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #5ac0ff;
    color: white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
  }
  .is_btn_status_process {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #5BB116;
    color: white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
  }

  .box {
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    box-shadow: none;
    border-radius: 0;
  }

  .space_div {
    height: 1rem;
  }

  h2.subtitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.28;
    color: #404254;
  }
  .parrafo {
    font-size: 16px;
    line-height: 1.29;
    letter-spacing: 0.14px;
    color: #404254;
  }
  label.label {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0.16px;
    color: #404254;
  }

  .material-icons.is-warning {
    color: #eb9e23;
  }
  .material-icons.is-danger {
    color: #ff3860;
  }
  .material-icons.is-success {
    color: #00b00c;
  }
  .icon_search {
    width: 15px;
  }

  .input_select .vs__dropdown-toggle {
    border-radius: 20px;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
    background-color: white;
    border-color: #dbdbdb;
    height: 2.5em;
    color: #363636;
    font-size: 14px;
  }
  .input_select.is-danger .vs__dropdown-toggle {
    border-color: var(--error-color);
  }
  .input_select .vs__search::placeholder {
    color: #5d699060;
    font-weight: 600;
  }
  .input_select .vs__dropdown-menu {
    margin: 1px 0;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .input_calendar .vc-rounded  {
    border-radius: 20px;
  }
  .icon_calendar, .icon_search {
    width: 15px;
  }
  .input_calendar .vc-text-base {
    font-size: 14px;
  }
  .input_calendar input::placeholder {
    color: #5d699060;
    font-size: 14px;
  }

  .link_back {
    color: #404254;
  }
  .link_back:hover {
    color: #2699fb;
  }
  .link_back .arrow_back {
    color: #2699fb;
    padding-right: 5px;
  }

  .control.has-icons-left .icon, .control.has-icons-right .icon {
    height: 100%;
  }

  /* Chrome Autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #363636 !important;
  }

  // Text with divider
  .text-divider {
    display: flex;
    align-items: center;
    --text-divider-gap: 1rem;
  }
  .text-divider::after {
    content: '';
    height: 1.5px;
    background-color: #e2e5ea;
    flex-grow: 1;
  }
  .text-divider::after {
    margin-left: 1.5rem;
  }

  // tabs
  .tabs.is-toggle li.is-active a {
    background-color: #EFF0F4;
    border-color: #dbdbdb;
    color: #404254;
  }
  .tabs.is-toggle a {
    background-color: white;
    color: #404254;
  }

  @media screen and (max-width: 768px) {
  }
  // spaces
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-40 {
    margin-top: 40px;
  }
</style>
